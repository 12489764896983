<template>
  <el-main>
    <page-title show-back-btn />
    <div v-loading="loading.detail" class="partition-area">
      <el-form ref="appForm" :model="appForm" :rules="rules" label-width="180px" :label-suffix="constants.labelSuffix">
        <ics-page-inner title="授信进度">
          <el-col :span="24">
            <el-steps :active="stepActiveIndex" finish-status="success">
              <el-step title="发起申请" />
              <el-step title="补件" />
              <el-step title="审批" />
              <el-step title="完成" />
            </el-steps>
          </el-col>
        </ics-page-inner>
        <ics-page-inner v-if="stepActiveIndex < 3" title="授信信息">
          <el-col :span="12">
            <el-form-item label="授信编号">
              <p>{{ utils.isEffectiveCommon(creditDetail.creditCode) }}</p>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="融资企业">
              <p>{{ utils.isEffectiveCommon(creditDetail.customerName) }}</p>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="核心企业">
              <p>{{ utils.isEffectiveCommon(creditDetail.coreName) }}</p>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="资方">
              <p>{{ utils.isEffectiveCommon(creditDetail.capName) }}</p>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="申请额度(元)">
              <p>{{ utils.moneyFormat(creditDetail.applyAmount, 2) }}</p>
            </el-form-item>
          </el-col>
        </ics-page-inner>
        <ics-product-info-inner :stage="productKey" :product-info="productInfo" />
        <ics-page-inner title="资产信息-基础合同">
          <el-col :span="24">
            <div class="partition-area">
              <el-table :data="contractBaseInfo" class="table-input" max-height="250">
                <el-table-column prop="contracNo" label="基础合同编号" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="100" />
                <el-table-column prop="contracName" label="基础合同名称" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="150" />
                <el-table-column prop="contracAmount" label="基础合同金额" align="center" :formatter="utils.tableMoneyFormat" show-overflow-tooltip min-width="150" />
                <el-table-column prop="signTime" label="签订时间" align="center" :formatter="(row, col, cell) => utils.dateFormat(cell)" show-overflow-tooltip min-width="150" />
                <el-table-column prop="dueTime" label="合同到期时间" align="center" :formatter="(row, col, cell) => utils.dateFormat(cell)" show-overflow-tooltip min-width="150" />
                <el-table-column label="操作" align="center" fixed="right" show-overflow-tooltip min-width="150">
                  <template v-slot="scope">
                    <el-link type="danger" class="text-btn" @click="clickDialog(scope.row)">
                      查看发票
                    </el-link>
                    <el-link type="danger" class="text-btn" @click="utils.downloadP('other', scope.row.filesList[0].url)">
                      下载
                    </el-link>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-col>
        </ics-page-inner>
        <ics-bank-files-inner :bank-files-info="bankFilesInfo" :bank-key="bankKey" :method="method" :flow-state-code="creditDetail.flowStateCode" />
        <ics-page-inner title="批复额度">
          <el-col :span="12">
            <el-form-item prop="btnCode" label="审批结果" :rules="{ required: true, message: '请选择审批结果', trigger: 'change' }">
              <el-radio-group v-model="appForm.btnCode" @change="btnCodeChange">
                <el-radio label="pass">
                  通过
                </el-radio>
                <el-radio label="back">
                  驳回
                </el-radio>
                <el-radio label="refusal">
                  拒绝
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col v-if="appForm.btnCode === 'pass'" :span="12">
            <el-form-item prop="taskData.passAmount" label="批复额度(元)">
              <el-input v-model="appForm.taskData.passAmount" type="number" placeholder="请输入批复额度" />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item prop="taskData.remarks" label="审批意见" :rules="{ required: true, message: '请输入审批意见', trigger: 'blur' }">
              <el-input v-model="appForm.taskData.remarks" placeholder="请输入审批意见(限300字)" :maxlength="300" type="textarea" />
            </el-form-item>
          </el-col>
          <ics-button-inner :loading="loading.submit" submit-title="提交" cancel-title="返回" @submit="submitForms" />
        </ics-page-inner>
      </el-form>
    </div>
    <ics-dialog-inner width="60%" :visible.sync="dialog.invoice.visible" :title="dialog.invoice.title" cancel-title="返回" class="form-dialog" :show-submit="false">
      <div class="partition-table">
        <el-table :data="invoicesList" border style="width: 100%" class="table-input" max-height="250">
          <el-table-column prop="invoiceCode" label="发票编号" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="100" />
          <el-table-column prop="invoiceKind" label="发票类型" align="center" :formatter="(row, col, cell) => utils.statusFormat(cell, 'invoiceKindType')" show-overflow-tooltip min-width="100" /><el-table-column prop="buyerTaxName" label="采购方" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="150" />
          <el-table-column prop="sellerTaxName" label="销售方" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="150" />
          <el-table-column prop="billingDate" label="开票时间" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="150" />
          <el-table-column prop="totalAmountTax" label="金额(元)" align="center" :formatter="utils.tableMoneyFormat" show-overflow-tooltip min-width="150" />
          <el-table-column prop="totalTax" label="税额(元)" align="center" :formatter="utils.tableMoneyFormat" show-overflow-tooltip min-width="150" />
          <el-table-column prop="invoiceStatus" label="状态" align="center" :formatter="(row, col, cell) => utils.statusFormat(cell, 'invoiceStatus')" show-overflow-tooltip min-width="150" />
          <el-table-column label="操作" fixed="right" align="center" show-overflow-tooltip min-width="150">
            <template slot-scope="scope">
              <a href="javascript:" class="text-btn" @click="utils.downloadP('invoice', scope.row.invoiceUrl)">下载</a>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </ics-dialog-inner>
  </el-main>
</template>

<script>
import { routeEnterMixin } from '@/assets/js/mixins'
import IcsProductInfoInner from './components/product-info-inner'
import IcsBankFilesInner from './components/bank-files-inner'
import IcsDialogInner from '@/components/dialog-inner'
export default {
  components: { IcsProductInfoInner, IcsBankFilesInner, IcsDialogInner },
  mixins: [routeEnterMixin],
  data() {
    const validatePassAmount = (rule, value, callback) => {
      if (Number(value) < 0) {
        callback(new Error('批复额度不能小于0'))
      } else if (Number(value) > Number(this.creditDetail.applyAmount)) {
        callback(new Error('批复额度不能大于申请额度'))
      } else {
        callback()
      }
    }
    return {
      creditCode: this.$route.query.creditCode,
      taskId: this.$route.query.taskId,
      appForm: {
        btnCode: '',
        btnName: '',
        btnStatus: '',
        opinion: '',
        taskData: {
          flowState: '',
          flowName: '',
          passAmount: '',
          remarks: ''
        }
      },
      stepActiveIndex: 0,
      bankKey: 0,
      productKey: 0,
      method: '',
      creditDetail: {},
      productInfo: {},
      bankFilesInfo: {},
      contractBaseInfo: [],
      invoicesList: [],
      dialog: {
        invoice: {
          visible: false,
          title: '查看发票信息'
        }
      },
      rules: {
        taskData: {
          passAmount: [
            { required: false, message: '请输入批复额度', trigger: 'blur' },
            { validator: validatePassAmount, trigger: 'blur' }
          ]
        }
      }
    }
  },
  created() {
    if (this.creditCode) {
      this.getDetail()
    }
  },
  methods: {
    getDetail () {
      this.loading.detail = false
      this.api.credit.creditDetails({ creditCode: this.creditCode }).then(result => {
        const data = result.data.data
        this.creditDetail = data.credit
        this.appForm.taskData.passAmount = this.creditDetail.passAmount ? this.creditDetail.passAmount : this.creditDetail.applyAmount
        this.contractBaseInfo = data.contractBaseInfo
        this.bankKey = data.snapshot.bankKey
        if (data.snapshot.productKey === 1) {
          this.productKey = 1
        }
        this.bankFilesInfo.files = data.files
        if (data.snapshot.productJson) {
          const productJson = JSON.parse(data.snapshot.productJson) || {}
          this.productInfo = productJson
          if (Number(productJson.repaymentMethod) === 0) {
            this.method = 0
          } else if (Number(productJson.repaymentMethod) === 1) {
            this.method = 1
          } else {
            this.method = ''
          }
        }
        if (data.snapshot.bankJson) {
          this.bankFilesInfo = JSON.parse(data.snapshot.bankJson)
        }
        switch (data.credit.flowStateCode) {
          case 'Wait':
            this.stepActiveIndex = 0
            break
          case 'Supplement_tenant':
            this.stepActiveIndex = 1
            break
          case 'Supplement_cap':
            this.stepActiveIndex = 1
            break
          case 'Cap_approve':
            this.stepActiveIndex = 2
            break
          case 'Fail':
            this.stepActiveIndex = 4
            break
          case 'Completed':
            this.stepActiveIndex = 4
            break
          default:
            this.stepActiveIndex = 0
        }
      }).finally(() => {
        this.loading.detail = false
      })
    },
    clickDialog (row) {
      this.invoicesList = row.invoicesList || []
      this.dialog.invoice.visible = true
    },
    btnCodeChange (value) {
      switch (value) {
        case 'pass':
          this.appForm.btnName = '通过'
          this.appForm.taskData.flowState = 'Audit'
          this.appForm.taskData.flowName = '审批'
          this.appForm.btnStatus = '已审核'
          this.rules.taskData.passAmount[0].required = true
          break
        case 'back':
          this.appForm.btnName = '驳回'
          this.appForm.taskData.flowState = 'Audit'
          this.appForm.taskData.flowName = '审批'
          this.appForm.btnStatus = '已驳回'
          this.rules.taskData.passAmount[0].required = false
          break
        case 'refusal':
          this.appForm.btnName = '拒绝'
          this.appForm.taskData.flowState = 'Audit'
          this.appForm.taskData.flowName = '审批'
          this.appForm.btnStatus = '已拒绝'
          this.rules.taskData.passAmount[0].required = false
          break
        default:
          this.appForm.btnName = ''
          this.appForm.btnStatus = ''
          this.appForm.taskData.flowState = 'Audit'
          this.appForm.taskData.flowName = '审批'
          this.rules.taskData.passAmount[0].required = false
      }
    },
    submitForms () {
      this.$refs.appForm.validate().then(() => {
        this.loading.submit = true
        if (this.appForm.btnCode === 'pass') {
          this.api.contract.isContractSign({creditCode: this.creditCode}).then(result => {
            if (result.data.success === true) {
              this.appForm.opinion = this.appForm.taskData.remarks
              this.api.credit.complete(this.taskId, this.appForm).then(result => {
                if (result.data.success === true) {
                  this.loading.submit = false
                  this.$message.success('操作成功')
                  this.$router.back()
                } else {
                  this.$message.error(result.data.message)
                }
              }).catch(e => {
                this.loading.submit = false
              })
            } else {
              this.$message.error(result.data.message)
              this.loading.submit = false
            }
          }).catch(e => {
            this.loading.submit = false
          })
        } else {
          this.api.credit.complete(this.taskId, this.appForm).then(result => {
            if (result.data.success === true) {
              this.loading.submit = false
              this.$message.success('操作成功')
              this.$router.back()
            } else {
              this.$message.error(result.data.message)
            }
          }).catch(e => {
            this.loading.submit = false
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
